import type, { AxiosResponse } from 'axios'
import axios from "axios";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const service = axios.create({
  baseURL: 'https://www.icspec.com/api',
})

service.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      if (config.params.token) {
        config.headers.token = config.params.token
      }
    } else {
      if (config.data.token) {
        config.headers.token = config.data.token
      }
    }

    return config
  },
  (error) => {
    return Promise.reject(error.response)
  },
)
service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response.status === 200) {
      return response
    }

    throw new Error(response.status.toString())
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default service
