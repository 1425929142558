
import { defineComponent, watchEffect, reactive, toRefs } from "vue";
import axios from "axios";
import eventBus from "../../../utils/eventBus";

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {},
    });

    watchEffect(() => {
      data.data = props.data;
    });
    const getGoodsOrderData = (data: any) => {
      return JSON.parse(data.split("goodsOrder?*")[1]);
    };
    const getGoodsOrderDataAuto = (data: any) => {
      return JSON.parse(data.split("goodsOrderAuto?*")[1]);
    };
    // const addCart = (data:any) => {
    //   axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    //   axios
    //     // .post("https://www.icspec.com/api/ictrade/goods/saveCartFixed", {
    //        .post("https://www.icspec.com/api/ictrade/goods/saveCartFixed", {
    //       data
    //     })
    //     .then((response) => {
    //       console.log(response)
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     });
    // };

    // /user/contacts/my

    const toIcspecReq = (id: any, reqid: any) => {
      axios
        .get("https://www.icspec.com/api/agent/model/detail?id=" + reqid)
        .then((res) => {
          if (res.data.code > 0) {
            window.open("https://www.icspec.com/inquiry/demandDetails/" + id);
          }
        });
    };
    const sendReadRC = (id: any) => {
      const params = new URLSearchParams(window.location.search);
      let IsReadList: any = localStorage.getItem("isReadList");
      IsReadList = IsReadList ? JSON.parse(IsReadList) : [];
      if (IsReadList.indexOf(id)<0) {
        axios
          .post("https://www.icspec.com/api/demand/recommend/read", { id })
          .then((res) => {
            if (res.data.code > 0) {
              IsReadList.push(id);
              localStorage.setItem("isReadList", JSON.stringify(IsReadList));
              // window.open("https://www.icspec.com/inquiry/demandDetails/"+id)
            }
          });
      }
    };

    const formattedDate = (times: any) => {
      const date = new Date(times * 1000); // 根据时间戳创建Date对象
      console.log(date, "date"); // Fri Jul 14 2023 16:29:44 GMT+0800 (中国标准时间) 'date'

      const year = date.getFullYear(); // 获取年份
      const month = date.getMonth() + 1; // 获取月份，需要加1
      const day = date.getDate(); // 获取日期
      const hour = date.getHours(); // 获取小时
      let minute: any = date.getMinutes(); // 获取分钟
      const second = date.getSeconds(); // 获取秒数
      if (minute < 10) {
        minute = "0" + minute;
      }
      return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    const getGoodsData = (data: any, arg: any) => {
      return JSON.parse(data.split("card?*")[1]);
    };

    const getMsgData = (str: any, data: any, arg: any) => {
      var obj: any = JSON.parse(data.split(`${str}?*`)[1]);
      return obj[arg];
    };

    const jsonOrder = (order: any) => {
      let idList = order.split(",");
      return idList;
    };

    const sendPrice = (data: any) => {
      eventBus.emit("sendPrice", data);
    };

    const format = (num: any) => {
      if (num != null && num && num != "null") {
        let [int, fraction = ""]: any = (num + "").split(".");
        let ans = "";
        const len = int.length;
        int = int.split("").reverse();
        for (let i = 0; i < len; i++) {
          if (i !== 0 && i % 3 === 0) {
            ans = int[i] + "," + ans;
          } else {
            ans = int[i] + ans;
          }
        }
        if (fraction !== "") ans += "." + fraction;
        return ans;
      } else {
        return "-";
      }
    };

    return {
      ...toRefs(data),
      getGoodsData,
      getGoodsOrderData,
      getMsgData,
      jsonOrder,
      formattedDate,
      toIcspecReq,
      sendReadRC,
      sendPrice,
      format,
      getGoodsOrderDataAuto,
      // addCart,
    };
  },
});
